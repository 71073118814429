import {
  isNotEmptyArray,
  isNumber,
  isString,
  isValidMongoId,
} from '../../../utils'

import { getSegmentKeys, getKeys } from './get-keys'

export const getTargetingParams = (values, options = {}) => {
  const { dsp, pmp, publishers } = options

  const params = {
    excludeCountry: values.excludeCountry === 'EXCLUDE',
    excludeDma: values.excludeDma === 'EXCLUDE',
    excludeiabCat: values.excludeiabCat === 'EXCLUDE',
    excludeApp: values.excludeApp === 'EXCLUDE',
    includeSegmentAnd: values.includeSegmentAnd === 'AND',
    excludeState: values.excludeState === 'EXCLUDE',
    excludeNetwork: values.excludeNetwork === 'EXCLUDE',
    excludeChannel: values.excludeChannel === 'EXCLUDE',
    excludeGenres: values.excludeGenres === 'EXCLUDE',
    excludeRating: values.excludeRating === 'EXCLUDE',
    excludeLanguage: values.excludeLanguage === 'EXCLUDE',
  }

  if (values.buyerId) {
    params.buyerId = values.buyerId
  }

  if (dsp || publishers) {
    params.excludeUserId = values.excludeUserId === 'EXCLUDE'

    if (isNotEmptyArray(values.publishers)) {
      params.userid = values.publishers
    }
  }

  if (pmp) {
    params.excludeDomain = values.excludeDomain === 'EXCLUDE'
    params.excludeBundle = values.excludeBundle === 'EXCLUDE'
    params.excludezip = values.excludezip === 'EXCLUDE'

    if (isNotEmptyArray(values.domain)) {
      params.domain = values.domain
    }

    if (isNotEmptyArray(values.bundle)) {
      params.bundle = values.bundle
    }

    if (isNotEmptyArray(values.zip)) {
      params.zip = values.zip
    }

    if (isNotEmptyArray(values.accountExcludeList)) {
      params.inventoryInfoAccIds = values.accountExcludeList
      params.excludeAccIds = true
    }

    if (isNotEmptyArray(values.appInfoIds)) {
      params.appInfoIds = values.appInfoIds
    }
  }

  if (isNotEmptyArray(values.marketplaceIds)) {
    params.marketplaceIds = values.marketplaceIds
  }

  if (isNotEmptyArray(values.platform)) {
    params.platform = values.platform.includes('ALL')
      ? ['ALL']
      : values.platform
  }

  if (isNotEmptyArray(values.mediaType)) {
    params.mediaType = values.mediaType[0]
  }

  if (isNotEmptyArray(values.country)) {
    params.country = values.country
  }

  if (
    isNotEmptyArray(values.country) &&
    (values.country.includes('US') || values.country.includes('CA'))
  ) {
    if (isNotEmptyArray(values.state)) {
      params.state = values.state
    }

    if (isNotEmptyArray(values.dma)) {
      params.dma = values.dma
    }

    if (values.zipMedia && isNotEmptyArray(values.zipMedia.ids)) {
      params.zipMedia = {
        ...values.zipMedia,
        ids: getKeys(values.zipMedia.ids, 'id'),
      }
    }

    if (values.dmaMedia && isNotEmptyArray(values.dmaMedia.ids)) {
      params.dmaMedia = {
        ...values.dmaMedia,
        ids: getKeys(values.dmaMedia.ids, 'id'),
      }
    }
  }

  if (isNotEmptyArray(values.genres) && !values.contentBooleanLogic?.enabled) {
    params.masterGenresList = values.genres.filter(
      (item) => isString(item) && isValidMongoId(item)
    )
    params.genres = values.genres.filter((item) => isNumber(item))
  }

  if (isNotEmptyArray(values.network) && !values.contentBooleanLogic?.enabled) {
    params.network = values.network
  }

  if (
    values.audienceBooleanLogic?.enabled &&
    Object.hasOwn(values.audienceBooleanLogic, 'value')
  ) {
    params.expression = values.audienceBooleanLogic?.value
  }

  if (
    values.contentBooleanLogic?.enabled &&
    Object.hasOwn(values.contentBooleanLogic, 'value')
  ) {
    params.contentExpression = values.contentBooleanLogic?.value
  }

  if (isNotEmptyArray(values.channel) && !values.contentBooleanLogic?.enabled) {
    params.channel = values.channel
  }

  if (isNotEmptyArray(values.iabCategory)) {
    params.iabCategory = values.iabCategory
  }

  if (isNotEmptyArray(values.rating)) {
    params.rating = values.rating
  }

  if (isNotEmptyArray(values.language)) {
    params.language = values.language
  }

  if (isNotEmptyArray(values.app)) {
    params.app = values.app
  }

  if (
    isNotEmptyArray(values.includeSegments) &&
    !values.audienceBooleanLogic?.enabled
  ) {
    params.includeSegments = getSegmentKeys(values.includeSegments)
  }

  if (
    isNotEmptyArray(values.excludeSegments) &&
    !values.audienceBooleanLogic?.enabled
  ) {
    params.excludeSegments = getSegmentKeys(values.excludeSegments)
  }

  if (values.domainMedia && isNotEmptyArray(values.domainMedia.ids)) {
    params.domainMedia = {
      ...values.domainMedia,
      ids: getKeys(values.domainMedia.ids, 'id'),
    }
  }

  const isMongoBundleId = values.bundleMedia?.ids?.some((el) =>
    isValidMongoId(el.id)
  )

  if (
    values.bundleMedia &&
    isNotEmptyArray(values.bundleMedia.ids) &&
    isMongoBundleId
  ) {
    params.bundleMedia = {
      ...values.bundleMedia,
      ids: getKeys(values.bundleMedia.ids, 'id'),
    }
  }

  params.includeLivestream = values.includeLivestream

  return params
}
