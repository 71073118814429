import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Tag } from '@beachfront/ui'

import { plural } from '../../../../utils'

const ContentPreviewTags = ({ visible, values }) => {
  if (!visible) {
    return null
  }

  const tags = []
  const channelCount = values.channelObj?.length ?? 0
  const networkCount = values.networkObj?.length ?? 0
  const genresCount = values.genresObj?.length ?? 0
  const catCount = values.iabCategoryObj?.length ?? 0
  const ratingCount = values.rating?.length ?? 0
  const languageCount = values.language?.length ?? 0

  if (
    values.contentBooleanLogic?.enabled &&
    values.contentBooleanLogic?.value
  ) {
    tags.push(
      <Tag key='advanced' color='processing' flex='none'>
        Advanced Logic
      </Tag>
    )
  }

  if (!values.contentBooleanLogic?.enabled) {
    if (channelCount) {
      tags.push(
        <Tag key='channel' color='processing' flex='none'>
          {plural`${channelCount} Channel[|s]`}
        </Tag>
      )
    }
    if (networkCount) {
      tags.push(
        <Tag key='network' color='processing' flex='none'>
          {plural`${networkCount} Network[|s]`}
        </Tag>
      )
    }
    if (genresCount) {
      tags.push(
        <Tag key='genre' color='processing' flex='none'>
          {plural`${genresCount} Genre[|s]`}
        </Tag>
      )
    }

    if (values.includeLivestream) {
      tags.push(
        <Tag key='channel' color='processing' flex='none'>
          Livestream: On
        </Tag>
      )
    }
  }

  if (catCount) {
    tags.push(
      <Tag key='cat' color='processing' flex='none'>
        {plural`${catCount} Categor[y|ies]`}
      </Tag>
    )
  }

  if (ratingCount) {
    tags.push(
      <Tag key='rating' color='processing' flex='none'>
        {plural`${ratingCount} Rating[|s]`}
      </Tag>
    )
  }

  if (languageCount) {
    tags.push(
      <Tag key='language' color='processing' flex='none'>
        {plural`${languageCount} Language[|s]`}
      </Tag>
    )
  }

  if (tags.length) {
    const max = 2
    return (
      <Flex gap={1} maxWidth={200} justifyContent='flex-end'>
        {tags.slice(0, max)}
        {tags.length > max ? (
          <Tag color='processing' flex='none'>
            +{tags.length - max}
          </Tag>
        ) : null}
      </Flex>
    )
  }

  return null
}

ContentPreviewTags.propTypes = {
  visible: PropTypes.bool,
  values: PropTypes.object,
}

export default ContentPreviewTags
