import React, { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import {
  Navigate,
  Link,
  useNavigate,
  useParams,
  useLocation,
} from 'react-router-dom'
import { Box, Button, Tabs, useToast } from '@beachfront/ui'
import { Form } from '@beachfront/ui/forms'

import { api } from '../../../client-api'
import { TIMEZONE } from '../../../enums'
import {
  isNotEmptyArray,
  getErrorMessage,
  isEmptyString,
  isValidMongoId,
  isString,
  isNumber,
} from '../../../utils'
import { PageHeader } from '../../../components'
import {
  getSegmentKeys,
  SettingForm,
  TargetingForm,
  validateForm,
  getKeys,
} from '../../media-plan'
import { dealTabs, formInitialValues } from '../@constants'

const LibraryCreate = () => {
  const { type } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const queryClient = useQueryClient()
  const toast = useToast()
  const [timezone, setTimezone] = useState(TIMEZONE.EST.key)
  const [availsDsp, setAvailsDsp] = useState('')
  const adminDeal = location.pathname.includes('/deal')
  const adminFeatureDeal = location.pathname.includes('/featured')
  const pageName = adminFeatureDeal ? 'featured' : 'library'
  const backUrl = `/deal/${pageName}`

  if (!type) {
    return <Navigate to={`${backUrl}/create/${dealTabs[0].key}`} replace />
  }

  const onTabChange = (type) => {
    navigate(`${backUrl}/create/${type}`)
  }

  const onSubmit = (values) => {
    if (
      values.title &&
      (!values.audienceBooleanLogic.enabled ||
        isNotEmptyArray(values?.audienceBooleanLogic.client)) &&
      (!values.contentBooleanLogic.enabled ||
        isNotEmptyArray(values?.contentBooleanLogic.client))
    ) {
      const request = adminDeal
        ? api.dspDeal.admin.createDeal
        : api.dspDeal.admin.createMediaPlan

      let targetData = {
        platform: values.platform.includes('ALL') ? ['ALL'] : values.platform,
        country: values.country || [],
        dma: [],
        state: [],
        includeLivestream: values.includeLivestream,
        excludeCountry: values.excludeCountry === 'EXCLUDE',
        excludeDma: values.excludeDma === 'EXCLUDE',
        excludeState: values.excludeState === 'EXCLUDE',
        excludeiabCat: values.excludeiabCat === 'EXCLUDE',
        excludeNetwork: values.excludeNetwork === 'EXCLUDE',
        excludeChannel: values.excludeChannel === 'EXCLUDE',
        excludeRating: values.excludeRating === 'EXCLUDE',
        excludeLanguage: values.excludeLanguage === 'EXCLUDE',
        excludeGenres: values.excludeGenres === 'EXCLUDE',
        excludeUserId: values.excludeUserId === 'EXCLUDE',
        domainMedia:
          values.domainMedia && isNotEmptyArray(values.domainMedia.ids)
            ? {
                ...values.domainMedia,
                ids: getKeys(values.domainMedia.ids, 'id'),
              }
            : null,
        bundleMedia:
          values.bundleMedia && isNotEmptyArray(values.bundleMedia.ids)
            ? {
                ...values.bundleMedia,
                ids: getKeys(values.bundleMedia.ids, 'id'),
              }
            : null,
        zipMedia: null,
        dmaMedia: null,
      }

      if (isNotEmptyArray(values.country) && values.country.includes('US')) {
        if (isNotEmptyArray(values.dma)) {
          targetData['dma'] = values.dma
        }
        if (isNotEmptyArray(values.state)) {
          targetData['state'] = values.state
        }
      }

      if (isNotEmptyArray(values.genres)) {
        targetData['masterListGenres'] = values.genres.filter(
          (item) => isString(item) && isValidMongoId(item)
        )
        targetData['genres'] = values.genres.filter((item) => isNumber(item))
      }
      if (isNotEmptyArray(values.channel)) {
        targetData['channel'] = values.channel
      }
      if (isNotEmptyArray(values.network)) {
        targetData['network'] = values.network
      }
      if (isNotEmptyArray(values.iabCategory)) {
        targetData['iabCategory'] = values.iabCategory
      }

      if (isNotEmptyArray(values.rating)) {
        targetData['rating'] = values.rating
      }

      if (isNotEmptyArray(values.language)) {
        targetData['language'] = values.language
      }

      if (isNotEmptyArray(values.publishers)) {
        targetData['userid'] = values.publishers
      }

      if (values.buyers) {
        targetData['buyerId'] = values.buyers
      }

      let postData = {
        audienceBooleanLogic: values.audienceBooleanLogic,
        contentBooleanLogic: values.contentBooleanLogic,
        title: values.title,
        status: values.status === 'ENABLE',
        mediaType: isNotEmptyArray(values.mediaType)
          ? values.mediaType[0]
          : null,
        targeting: targetData,
        includeSegments: isNotEmptyArray(values.includeSegments)
          ? getSegmentKeys(values.includeSegments)
          : [],
        excludeSegments: isNotEmptyArray(values.excludeSegments)
          ? getSegmentKeys(values.excludeSegments)
          : [],
        includeSegmentAnd: values.includeSegmentAnd === 'AND',
      }

      if (adminFeatureDeal) {
        postData = {
          ...postData,
          desc: values.desc,
          icon: values.icon,
          featured: true,
        }
      }

      return request(postData).then(
        (res) => {
          if (res.data?.success) {
            queryClient.invalidateQueries({ queryKey: ['deal-library'] })
            queryClient.invalidateQueries({ queryKey: ['featured-deals'] })
            toast.success({ title: 'Deal created.' })
            setTimeout(() => {
              navigate(backUrl)
            }, 500)
          } else {
            toast.error({
              title:
                res.data?.msg ||
                res.data.errorDetails ||
                'Unable to create this deal. Please try again.',
            })
          }
        },
        (error) => {
          toast.error({ title: getErrorMessage(error) })
        }
      )
    } else {
      let field = ''
      let tab = ''
      if (
        values.title &&
        values.audienceBooleanLogic.enabled &&
        !isNotEmptyArray(values?.audienceBooleanLogic.client)
      ) {
        field = 'Advance Boolean Logic'
        tab = 'targeting'
      } else if (
        values.title &&
        values.contentBooleanLogic.enabled &&
        !isNotEmptyArray(values?.contentBooleanLogic.client)
      ) {
        field = 'Content Boolean Logic'
        tab = 'targeting'
      }
      toast.error({
        title: `Please fill ${isEmptyString(field) ? 'title' : field} in ${
          isEmptyString(tab) ? 'setting' : tab
        } tab`,
      })
    }
  }

  return (
    <Form
      initialValues={formInitialValues}
      onSubmit={onSubmit}
      validate={(values) => validateForm({ values, tab: type }, false)}
    >
      {({ handleSubmit, pristine, submitting, values }) => (
        <form onSubmit={handleSubmit}>
          <PageHeader
            title={
              pageName === 'library' ? 'Create Deal' : 'Create Featured Deal'
            }
            actions={
              <>
                <Link to={backUrl}>
                  <Button>Cancel</Button>
                </Link>
                <Button
                  disabled={pristine}
                  loading={submitting}
                  htmlType='submit'
                  type='primary'
                >
                  Create
                </Button>
              </>
            }
          />
          <Box mt={3}>
            <Tabs activeKey={type} onChange={onTabChange} type='card'>
              {dealTabs.map((t) => {
                const FormComponent = getFormComponent(t.key)
                return (
                  <Tabs.TabPane tab={t.title} key={t.key}>
                    <FormComponent
                      availsDsp={availsDsp}
                      dspDeal={true}
                      showTitle={false}
                      values={values}
                      timezone={timezone}
                      onTimezoneChange={setTimezone}
                      adminFeatureDeal={adminFeatureDeal}
                      onAvailsDspChange={setAvailsDsp}
                    />
                  </Tabs.TabPane>
                )
              })}
            </Tabs>
          </Box>
        </form>
      )}
    </Form>
  )
}

function getFormComponent(type) {
  switch (type) {
    case 'settings':
      return SettingForm
    case 'targeting':
      return TargetingForm
    default:
      return SettingForm
  }
}

export default LibraryCreate
